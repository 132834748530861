import circle from "../assets/circle.webp";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactSlackChat } from "react-slack-chat";

import { useState, useEffect } from "react";

const Registry = () => {
  const onChange = (value) => {
    setCaptcha(true);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [event, setEvent] = useState("1");
  const [nameRegister, setNameRegister] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showSlack, setSchowSlack] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  useEffect(() => {
    setTimeout(() => setSchowSlack(true), 2000);
  });

  const submitHandler = (e) => {
    e.preventDefault();

    if (!captcha) return;

    const data = { name, email, event };

    fetch("/api/guests", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((result) => result.json())
      .then((data) => {
        setError(false);
        setMessage(data.message);
        setNameRegister(name);
      })
      .catch((err) => {
        setError(true);
        setMessage("Es ist ein Fehler aufgetreten!");
      });

    setName("");
    setEmail("");
    setEvent("1");
  };
  return (
    <section
      className="section contact-area"
      style={{ backgroundImage: `url(${circle})`, backgroundRepeat: "repeat" }}
    >
      {showSlack && (
        <ReactSlackChat
          botName="wedding-bot"
          apiToken="eG94Yi0xNzgxOTMwMTc3NTkxLTIyMjc4NTgxMzk3NjEteXBQaU9UUjhuQXlxb0YybXBUM0hsYVhV"
          channels={[
            {
              name: "wedding-guests",
            },
            {
              name: "Gast",
              id: "",
              icon: "",
            },
          ]}
          helpText="Hast du Fragen?"
          themeColor="#EB347D"
          userImage="http://www.iconshock.com/img_vista/FLAT/mail/jpg/robot_icon.jpg"
          closeChatButton={true}
          singleUserMode={true}
        />
      )}
      <div className="container" id="anmeldung">
        <div className="row">
          <div className="col-sm-8 col-md-8 col-lg-8">
            <div className="contact-form margin-bottom">
              {message.length > 0 && (
                <Alert
                  nameRegister={nameRegister}
                  message={message}
                  error={error}
                />
              )}

              {message.length < 1 && (
                <h2 className="center-text">Feier mit uns?</h2>
              )}
              {message.length < 1 && (
                <h4 className="pre-title center-text">Hier anmelden!</h4>
              )}

              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-sm-12 margin-bottom">
                    <label>Name</label>
                    <input
                      className="name-input"
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 margin-bottom">
                    <label>E-mail</label>
                    <input
                      className="email-input"
                      type="text"
                      placeholder="E-Mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-6 margin-bottom">
                    <label>Event</label>
                    <select
                      className="event-select"
                      name="option"
                      value={event}
                      onChange={(e) => setEvent(e.target.value)}
                    >
                      <option value="1">Ab 14 Uhr</option>
                      <option value="2">Ab 18 Uhr</option>
                    </select>
                  </div>

                  <div className="col-sm-6" center-text>
                    <ReCAPTCHA
                      sitekey="6LcRjl8bAAAAACUq2T9YXWrng-Rw294QUdPRqKwy"
                      size="compact"
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-sm-12 center-text">
                    <button className="btn">
                      <b>Ich bin dabei</b>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Alert = ({ nameRegister, message, error }) => {
  return (
    <div
      className="alert alert-dismissible fade show"
      className={error ? "alert-danger" : "alert-success"}
      role="alert"
    >
      {message} <strong> {error || nameRegister + " ;)"}</strong>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      />
    </div>
  );
};

export default Registry;
