import circle from "../assets/circle.webp";

const Footer = () => {
  return (
    <footer
      style={{ backgroundImage: `url(${circle})`, backgroundRepeat: "repeat" }}
    >
      <div className="container center-text"></div>
    </footer>
  );
};

export default Footer;
