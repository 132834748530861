import logoWhite from "../assets/logo-white.png";

const Header = () => {
  return (
    <header>
      <div className="container">
        <a className="logo" href="index.html#">
          <img src={logoWhite} alt="Logo" />
        </a>
        <div className="menu-nav-icon" data-nav-menu="#main-menu">
          <a href="#anmeldung" className="icon icon-bars"></a>
        </div>
        <ul className="main-menu visible-on-click" id="main-menu">
          <li>
            <a href="#anmeldung">ANMELDUNG</a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
