import { GoogleMap, LoadScript, Marker, InfoBox } from "@react-google-maps/api";
import heart from "../assets/heart.png";

const containerStyle = {
  height: "100vh",
};

const box = {
  lat: 53.54452131861392,
  lng: 9.908374354745508,
};

const position = {
  lat: 53.54447667824048,
  lng: 9.907805724885188,
};

const options = {
  closeBoxURL: "true",
  enableEventPropagation: true,
};

const Map = () => {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDFf8SuwNlopLkVg7SBBURtOlNt5iGiCVw"
      id="ort"
    >
      <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={18}>
        <Marker position={position} icon={heart} />

        <InfoBox options={options} position={box}>
          <div
            style={{ backgroundColor: "yellow", opacity: 0.75, padding: 12 }}
          >
            <div style={{ fontSize: 16, fontColor: "white" }}>
              Restaurant Süßwasser, Elbchaussee 183
            </div>
          </div>
        </InfoBox>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
