import Header from "./components/Header";
import Wedding from "./components/Wedding";
import Timer from "./components/Timer";
import WeddingDetails from "./components/WeddingDetails";
import Registry from "./components/Registry";
import Map from "./components/Map";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <Header />
      <Wedding />
      <WeddingDetails />
      <Timer />
      <Registry />
      <Map />
      <Footer />
    </>
  );
};

export default App;
