const WeddingDetails = () => {
  return (
    <section className="section w-details-area center-text">
      <div className="container">
        <div className="row">
          <div className="col-sm-1"></div>
          <div className="col-sm-10">
            <div className="heading">
              <h2 className="title">Der schönste Tag</h2>
              <span className="heading-bottom">
                <i className="icon icon-star"></i>
              </span>
            </div>
            <div className="wedding-details margin-bottom">
              <div className="w-detail right">
                <i className="icon icon-ciurclke"></i>
                <h4 className="title">STANDESAMT</h4>
                <a href="https://www.hamburg.de/mitte/standesamt/">
                  Hier dürfen leider nur wenige dabei sein.
                </a>
              </div>
              <div className="w-detail left">
                <i className="icon icon-camera"></i>
                <h4 className="title">LUNCH AN DER ELBE</h4>
                <a href="http://www.suesswasser-hamburg.de/">
                  Wir treffen uns im Restaurant Süßwasser.
                </a>
              </div>
              <div className="w-detail right">
                <i className="icon icon-cake"></i>
                <h4 className="title">HOCHZEITS TORTE</h4>
                <p>Der wichtigste Moment.</p>
              </div>
              <div className="w-detail left">
                <i className="icon icon-wine-glass"></i>
                <h4 className="title">Stoff und Schnaps</h4>
                <p>jetzt geht es richtig los.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeddingDetails;
