import aliceTino from "../assets/header.jpeg";
const Wedding = () => {
  return (
    <>
      <div
        className="main-slider"
        style={{
          backgroundImage: `url(${aliceTino})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="display-table center-text">
          <div className="display-table-cell">
            <div className="slider-content">
              <i className="small-icon icon icon-tie"></i>
              <h5 className="date">04 August 2021</h5>
              <h3 className="pre-title">Wir heiraten</h3>
              <h1 className="title">
                Alice <i className="icon icon-heart"></i> Tino
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wedding;
